import React from 'react';
import Layout from '../components/layout';
import { alternateLinks } from '../data/site-data';
import Hero from '../components/common/hero';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Pandev Hukuk | ABD Göçmenlik Avukatları | Göçmenlik Hukuk Bürosu',
  description:
    'Yatırımcı Göçmenlik, İstihdam Temelli Göçmenlik, Aile Temelli Göçmenlik ve İş Hukuku alanlarında geniş deneyime sahibiz. Danışmanlık için bizimle iletişime geçin.',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "ABD Göçmenlik Avukatları",
  "image": "https://www.pandevlaw.com/img/statue-liberty.jpg",
  "description": "Yatırımcı Göçmenlik, İstihdam Temelli Göçmenlik, Aile Temelli Göçmenlik ve İş Hukuku alanlarında geniş deneyime sahibiz. Danışmanlık için bizimle iletişime geçin.",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  }
}`;

function AbdGocmenLikAvukatlari({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema} lang="tr-TR">
      <Hero
        title="Amerika Yolculuğunuz Burada Başlıyor"
        isTitleBgTransparent
        hideContactLink>
        <div className="text-bg-white">
          <p className="text-lead mb-5 mb-md-6">
            <strong>Pandev Hukuk</strong>
            <span>
              , olağan&uuml;st&uuml; y&uuml;ksek kalitede temsil sağlamaya kendini adamış
              bir ticaret ve g&ouml;&ccedil;menlik hukuk firmasıdır. "Amerika yolculuğunuz
              burada başlıyor" sloganı her şeyi anlatmaktadır. Uluslararası şirketlerin ve
              bireylerin ABD'ye taşınmasına ve&nbsp;
            </span>
            <strong>Green Card ile vatandaşlık</strong>
            <strong>, Yatırımcı Vizeleri ve Turist Vizeleri</strong>
            <span>
              &nbsp;başvurularında yardımcı olma konusunda uzman durumunda ve ABD ile
              d&uuml;nya &ccedil;apında bireysel ve kurumsal m&uuml;şterileri etkin bir
              şekilde &ouml;zellikle&nbsp;
            </span>
            <strong>Green Card, </strong>
            <strong>Yatırımcı Vizeleri ve Turist Vizeleri</strong>
            <span>&nbsp;başvurularında temsil etmek i&ccedil;in </span>
            <span>Green Card Avukat </span>
            <span>olarak </span>
            <span>benzersiz bir konumda bulunmaktayız.</span>
          </p>
        </div>
      </Hero>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <p className="text-body mb-5">
              <p>
                <span>
                  G&ouml;&ccedil;menlik tecr&uuml;belerimiz, &ouml;zellikle EB-5 ve E-2
                  Anlaşması Yatırımcıları, H-1B Uzman &Ccedil;alışanları ve L-1 Şirket
                  İ&ccedil;i Transferler olmak &uuml;zere; E2 yatırımcı ve istihdama
                  dayalı g&ouml;&ccedil; &uuml;zerine odaklanmaktadır. Ayrıca, evlilik,
                  nişanlılık ve diğer aile temelli dilek&ccedil;eler yoluyla ailelerin
                  birleşmesine yardımcı olunmaktadır. Akademisyenler, araştırmacılar,
                  bilim adamları, sanat&ccedil;ılar ve sporcular dahil olmak &uuml;zere
                  &ccedil;ok uluslu y&ouml;neticiler ve olağan&uuml;st&uuml; yetenekli
                  bireyler i&ccedil;in PERM &Ccedil;alışma Sertifikaları, EB-2 Ulusal
                  &Ccedil;ıkar Feragatnameleri ve EB-1 dilek&ccedil;eleri dahil olmak
                  &uuml;zere; istihdama dayalı&nbsp;
                </span>
                <strong>ABD </strong>
                <strong>Green Card ile vatandaşlık</strong>
                <span>&nbsp;s&uuml;recinde ve&nbsp;</span>
                <strong>Yatırımcı Vizeleri ve Turist Vizeleri</strong>
                <span>&nbsp;konusunda kapsamlı deneyimimiz ve &ccedil;ok fazla </span>
                <span>Amerika&rsquo;da </span>
                <span>Green Card avukat</span>
                <span>lık deneyimimiz </span>
                <span>bulunmaktadır. </span>
                <span>ABD vatandaşlığı nasıl alınır</span>
                <span>
                  ? Sorusunun cevabı i&ccedil;in l&uuml;tfen yazımızın tamamını okuyunuz.
                </span>
              </p>
            </p>
            <p className="text-body">
              <p>
                <span>
                  İş tecr&uuml;belerimiz, işinizi ABD'nde başlatmanıza ve
                  b&uuml;y&uuml;tmenize yardımcı olmaya odaklanmaktadır. Girişimcilere,
                  k&uuml;&ccedil;&uuml;k ve orta &ouml;l&ccedil;ekli işletmelere oluşum ve
                  y&ouml;netim, birleşme ve devralmalar, ticari işlemler, finansman ve
                  vergiyi verimli kullanan anlaşma yapılandırması konularında tavsiyelerde
                  bulunmaktayız. D&uuml;zenli olarak sınır &ouml;tesi işlemler hakkında
                  tavsiyelerde bulunuyor ve ABD pazarına girmek isteyen yabancı
                  şirketleri&nbsp;
                </span>
                <strong>Green Card ile vatandaşlık</strong>
                <strong>, Yatırımcı Vizeleri ve Turist Vizeleri</strong>
                <span>
                  &nbsp;konusunda temsil ediyoruz. Telif hakkı ve ticari marka tescilleri
                  yoluyla fikri m&uuml;lkiyetinizin korunmasına yardımcı oluyor; lisans
                  s&ouml;zleşmeleri ve sınır &ouml;tesi teknoloji transferleri gibi fikri
                  m&uuml;lkiyet işlemleri konusunda tavsiyelerde bulunuyoruz.
                </span>
              </p>
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-body">
              <p>
                <span>Pandev Hukuk olarak </span>
                <span>Amerika&rsquo;ya g&ouml;&ccedil;menlik</span>
                <span> konusunda </span>
                <span>
                  merkezi Tekirdağ ve İstanbul&rsquo;da bulunun T&uuml;rkiye ortak
                  ofislerimiz aracılığıyla, sizin veya şirketinizin ABD'ne taşınmasına
                  yardımcı olmak i&ccedil;in stratejik olarak konumlanmış bulunmaktayız.{' '}
                </span>
                <span>ABD g&ouml;&ccedil;menlik</span>
                <span>
                  {' '}
                  b&uuml;rosu ve T&uuml;rkiye'deki yerel ekibimiz size T&uuml;rk&ccedil;e
                  tavsiyelerde bulunmaktan mutluluk duyacaktır.&nbsp;
                </span>
                <a href="mailto:izel@pandevlaw.com">
                  <span>izel@pandevlaw.com&nbsp;</span>
                </a>
                <span>
                  adresinden bize e-posta g&ouml;nderiniz veya iletişim formumuzu
                  doldurunuz.
                </span>
              </p>
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Avukatımızla Bugün bir Görüşme Planlayın"
        buttonText="AVUKATIMIZA DANIŞIN"
      />

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12">
            <h2 className="text-bold mb-3 mb-md-4">
              <strong>Yeşil Kart (Green Card)</strong>
            </h2>
            <p>
              <span>ABD'ye seyahat etmek veya </span>
              <span>ABD oturma izni</span>
              <span>
                {' '}
                istiyorsanız, "ABD green card" ile "vize" arasındaki farkın ne olduğunu
                merak ediyor olabilirsiniz. Bu kılavuzla vize ve ABD green card arasındaki
                farkları &ouml;ğrenin.
              </span>
            </p>
            <ol>
              <li>
                <span>Vize Nedir?</span>
              </li>
              <li>
                <span>ABD green card Nedir?</span>
              </li>
              <li>
                <span>ABD green card ile Vize Arasındaki Farklar Nelerdir?</span>
              </li>
            </ol>
            <p />
            <h2 className="text-bold mb-3 mb-md-4">
              <strong>Vize Nedir?</strong>
            </h2>
            <p>
              <span>
                ABD'ye seyahat etmek veya g&ouml;&ccedil; etmek mi istiyorsunuz?{' '}
              </span>
              <span>ABD oturma izni</span>
              <span> ihtiyacınız mı var? ABD </span>
              <span>green card avukatlık</span>
              <span>
                {' '}
                Vize, ABD'ye girmek isteyen &ccedil;oğu kişinin kabul edilmek i&ccedil;in
                sahip olması gereken onaydır. Vize i&ccedil;in seyahate &ccedil;ıkmadan
                &ouml;nce ABD el&ccedil;iliği veya konsolosluğu aracılığıyla ve{' '}
              </span>
              <span>ABD g&ouml;&ccedil;menlik</span>
              <span>
                {' '}
                b&uuml;rosu, ABD green card vize başvurusu yapılmalıdır. İki t&uuml;r vize
                vardır:
              </span>
            </p>
            <ol>
              <li>
                <span>
                  G&ouml;&ccedil;men olmayan vizeler: Bunlar, sahibinin iş, eğitim, tıbbi
                  nedenler veya iş gezileri gibi belirli ama&ccedil;larla ve net bir
                  ayrılış tarihi ile ABD green card aksine belirli bir s&uuml;re
                  i&ccedil;in ABD'yi ziyaret etmesine izin verir,{' '}
                </span>
                <span>ABD oturma izni</span>
                <span>
                  {' '}
                  vermez. Bu vizeler, sahibinin ABD'de kalıcı olarak yaşamasına izin
                  vermeyen belgelerdir.
                </span>
              </li>
              <li>
                <span>
                  G&ouml;&ccedil;men vizeleri: Alınması daha zor olan bu vize
                  t&uuml;r&uuml;, ABD'ye seyahat eden kişinin orada kalıcı olarak
                  yaşamasına izin verir ve ABD green card alma s&uuml;recinin bir
                  par&ccedil;asıdır. Bu ABD green card vize, ABD'ne seyahat etmeden
                  &ouml;nce alınmalıdır ve ABD green card vize alma ve{' '}
                </span>
                <span>ABD oturma izni</span>
                <span>
                  {' '}
                  s&uuml;reci daha kapsamlıdır bu y&uuml;zden Amerikada avukatlık
                  tecr&uuml;besi olan hukuk firmalarıyla &ccedil;alışmanızı
                  &ouml;neriyoruz. Genellikle ABD green card vize i&ccedil;in sahibine bir
                  aile &uuml;yesi sponsor olması gerekir. ABD'de kalıcı olarak kalmak
                  istiyorsanız, ABD{' '}
                </span>
                <span>green card ile vatandaşlık</span>
                <span> ihtiyacınız olacaktır.</span>
              </li>
            </ol>
            <h2 className="text-bold my-3 my-md-4">
              <strong>ABD Green Card Nedir?</strong>
            </h2>
            <p>
              <span>
                ABD green card, kart sahibinin ABD'de daim&icirc; ikamet ettiğini
                g&ouml;steren fiziksel karttır ve ABD green card sahibi ABD'nin herhangi
                bir yerinde yasal olarak &ccedil;alışabilir ve seyahat edebilir. ABD green
                card teknik olarak kalıcı ikamete izin veren bir vize
                t&uuml;r&uuml;d&uuml;r. ABD green card, ABD'ye geldikten sonra verilir.
                ABD{' '}
              </span>
              <span>green card ile vatandaşlık</span>
              <span>
                {' '}
                almaya hak kazanmak i&ccedil;in, başvuru sahibinin halihazırda bir
                g&ouml;&ccedil;men vizesine sahip olması gerekir ve ABD{' '}
              </span>
              <span>green card avukatlık</span>
              <span>
                {' '}
                i&ccedil;in ABD Vatandaşlık ve G&ouml;&ccedil;menlik Hizmetlerine (USCIS)
                başvuru yapılır.
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>Aile temelli ABD </span>
                <span>green card ile vatandaşlık</span>
                <span>
                  : Mevcut ABD vatandaşlarının yakın akrabaları ve diğer ABD green card
                  sahipleri başvurabilir.
                </span>
              </li>
              <li>
                <span>
                  İstihdama dayalı ABD green card: Belirli t&uuml;rdeki iş&ccedil;ilere ve
                  bazı durumlarda yakın ailelerine işlerine bağlı olarak ABD{' '}
                </span>
                <span>green card ile vatandaşlık</span>
                <span> verilebilir.</span>
              </li>
            </ul>
            <h2 className="text-bold my-3 my-md-4">
              <strong>ABD green card ile Vize Arasındaki Farklar Nelerdir?</strong>
            </h2>
            <p>
              <span>
                Vize ile ABD green card arasındaki en b&uuml;y&uuml;k fark, ne zaman
                alınacağıdır: vize seyahatten &ouml;nce, ABD green card ise varıştan sonra
                alınır.
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  Vize ABD green card aksine seyahate &ccedil;ıkmadan &ouml;nce
                  alınmalıdır. ABD'ye girişe izin veren tipik olarak pasaporttaki
                  damgadır.
                </span>
              </li>
              <li>
                <span>Vizeler, ABD de a&ccedil;ık u&ccedil;lu bir kalışa ve </span>
                <span>ABD oturma izni</span>
                <span>
                  {' '}
                  vermez. G&ouml;&ccedil;men olmayan vizelerin ABD de belirli bir
                  s&uuml;resi vardır, bu s&uuml;re seyahat nedenine bağlı olarak
                  değişebilir.
                </span>
              </li>
              <li>
                <span>
                  ABD green card, sahibinin ABD'de kalıcı olarak yaşama ve &ccedil;alışma
                  hakkı veren fiziksel bir karttır.
                </span>
              </li>
              <li>
                <span>ABD green card yalnızca ABD'ye varıştan sonra alınır.</span>
              </li>
              <li>
                <span>
                  ABD green card almadan &ouml;nce g&ouml;&ccedil;men vizesi alınmalıdır.
                </span>
              </li>
              <li>
                <span>ABD green card sahibi vatandaşlık i&ccedil;in başvurabilir.</span>
              </li>
            </ul>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Evlilik Yoluyla ABD green card</strong>
            </h2>
            <h2 className="text-bold my-3 my-md-4">
              <strong>
                <em>Evlilik yoluyla ABD green card nasıl alınır? </em>
              </strong>
              <strong>
                <em>ABD vatandaşlığı nasıl alınır</em>
              </strong>
              <strong>
                <em>?</em>
              </strong>
            </h2>
            <p>
              <span>
                Siz ve eşiniz ABD'de kalıcı olarak birlikte yaşamayı planlıyorsanız,
                aklınızdaki bir sonraki plan evlilik temelli ABD green card başvurusu
                olabilir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>
                <em>Evlilik yoluyla alınan ABD green card nedir?</em>
              </strong>
            </h2>
            <p>
              <span>Evlilik temelli ABD </span>
              <span>green card ile vatandaşlık</span>
              <span>
                {' '}
                bir ABD vatandaşı veya ABD green card sahibinin eşinin ABD'nin herhangi
                bir yerinde yaşamasına ve &ccedil;alışmasına izin verir. ABD{' '}
              </span>
              <span>
                green card sahibi, isterse &uuml;&ccedil; yıl sonra uygun hale geleceği
                ABD vatandaşlığına ve{' '}
              </span>
              <span>Amerika&rsquo;ya g&ouml;&ccedil;menlik </span>
              <span>başvurmaya </span>
              <span>karar verene kadar &ldquo;daim&icirc; ikamet&rdquo; yani </span>
              <span>ABD oturma izni</span>
              <span> stat&uuml;s&uuml;ne sahip olacaktır.</span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>
                <em>Nasıl evlilik temelli ABD green card alabilirim?</em>
              </strong>
            </h2>
            <p>
              <span>
                Evlilik yoluyla ABD green card almak genellikle &uuml;&ccedil; aşamalı bir
                s&uuml;re&ccedil;tir:
              </span>
            </p>
            <ol>
              <li>
                <span>Evlilik ilişkisini kurun (Form I-130)</span>
              </li>
              <li>
                <span>
                  ABD green card i&ccedil;in başvurun (Form I-485 veya Form DS-260)
                </span>
              </li>
              <li>
                <span>
                  ABD green card g&ouml;r&uuml;şmesine katılın ve onayı bekleyin
                </span>
              </li>
            </ol>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Aşama 1</strong>
            </h2>
            <p>
              <span>Evlilik ilişkisinin kurulması (Form I-130)</span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Evlilik yoluyla ABD green card alma s&uuml;recindeki ilk adım, ABD green
                card i&ccedil;in Form I-130'u ABD İ&ccedil;işleri Bakanlığı'nın bir
                par&ccedil;ası olan ABD Vatandaşlık ve G&ouml;&ccedil;menlik Hizmetleri'ne
                (USCIS) teslim etmektir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                ABD green card I-130 formunun temel amacı, ABD green card i&ccedil;in
                destekleyici belgelerle birlikte, ge&ccedil;erli bir evliliğin var
                olduğunu belirlemektir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                ABD green card I-130 belgesini veren eş, "dilek&ccedil;e sahibi" veya
                "sponsor" olarak adlandırılır. Bu, ABD vatandaşı veya mevcut ABD green
                card sahibi olan eştir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Tam bir ABD green card I-130 dosyalama paketinin kritik unsurları şunları
                i&ccedil;erir:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  535 $ 'lık h&uuml;k&uuml;met ABD green card dosyalama &uuml;creti
                </span>
              </li>
              <li>
                <span>
                  Sponsor eşin ABD vatandaşı olduğunun kanıtı (&ouml;rneğin sponsorun
                  doğum belgesinin, vatandaşlığa kabul belgesinin veya ge&ccedil;erli ABD
                  pasaport fotoğraflı sayfasının kopyası) veya daim&icirc; ikamet eden
                  kişinin (&ouml;rneğin sponsorun ABD green cardının kopyası)
                </span>
              </li>
            </ul>
            <p>
              <span>
                ABD green card I-130 paketi tamamlandığında, uygun USCIS adresine
                postalanmalıdır. USCIS daha sonra sponsor eşe postayla ABD green card
                başvurusu i&ccedil;in, genellikle iki hafta i&ccedil;inde resmi bir alındı
                belgesi g&ouml;nderecektir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Adım 2</strong>
            </h2>
            <h4 className="text-bold my-3 my-md-4">
              <strong>Eşin ABD green card i&ccedil;in uygunluğunun belirlenmesi</strong>
            </h4>
            <p>
              <span>ABD </span>
              <span>h&uuml;k&uuml;meti </span>
              <span>Amerika&rsquo;ya g&ouml;&ccedil;menlik</span>
              <span>, eşin evlilik </span>
              <span>
                temelli ABD green card almaya uygun olup olmadığını belirlemek i&ccedil;in
                iki farklı s&uuml;re&ccedil; izliyor.
              </span>
              <span>
                <br />
              </span>
              <span>ABD&rsquo;de ikamet eden eşler i&ccedil;in </span>
              <span>Amerika g&ouml;&ccedil;menlik başvurusu</span>
              <span> ve ABD green card;</span>
              <span>
                <br />
              </span>
              <span>
                ABD green card isteyen eş fiziksel olarak ABD'de yaşıyorsa, bir sonraki
                adım ABD green card Form I-485'i dosyalamaktır ve{' '}
              </span>
              <span>ABD g&ouml;&ccedil;menlik başvurusu</span>
              <span>
                {' '}
                b&uuml;rosu ile iletişime ge&ccedil;mektir. I-485, USCIS asıl amacı, eşin
                ABD green card uygunluğunu belirlemektir.{' '}
              </span>
              <span>ABD vatandaşlığı nasıl alınır</span>
              <span>? Sorusunun cevabını yukarıda bulabilirsiniz.</span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                ABD green card I-485 paketinin kritik unsurları şunları i&ccedil;erir:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  ABD green card isteyen eşin uyruğunun kanıtı (n&uuml;fus c&uuml;zdanı
                  fotokopisi ve pasaport fotoğraf sayfası)
                </span>
              </li>
              <li>
                <span>
                  ABD green card isteyen eşin ABD yasal girişinin kanıtı (I-94 seyahat
                  kaydının ve &ouml;nceki ABD vizesinin kopyası)
                </span>
              </li>
            </ul>
            <p>
              <span>
                ABD green card sahiplerinin eşleri ABD Dışişleri Bakanlığı ABD green card
                mevcut olduğunu belirlerse, ABD green card I-485 dosyalama paket
                g&ouml;nderilemez.
              </span>
              <span>
                <br />
              </span>
              <span>Yurtdışında yaşayan eşler i&ccedil;in ABD green card;</span>
              <span>
                <br />
              </span>
              <span>
                Yurt dışında yaşayan bir eş i&ccedil;in ABD green card sponsor olmanın
                farklı bir s&uuml;reci vardır. Bir sonraki adım, Dışişleri Bakanlığı
                tarafından y&uuml;r&uuml;t&uuml;len Ulusal Vize Merkezi'ne (NVC) bir
                başvuru paketi sunmaktır.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Bir ABD Green Card NVC dosyalama paketinin kritik unsurları şunları
                i&ccedil;erir:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  445 ABD doları tutarında ABD Green Card devlet dosyalama &uuml;cretleri
                  (mali destek formu i&ccedil;in 120 ABD doları ve Dışişleri Bakanlığı
                  işlem &uuml;creti i&ccedil;in 325 ABD doları dahil)
                </span>
              </li>
              <li>
                <span>
                  Form DS-260 (ABD green card başvurusu &ccedil;evrimi&ccedil;i olarak
                  yapılır)
                </span>
              </li>
              <li>
                <span>
                  ABD green card isteyen eşin uyruğunun kanıtı (n&uuml;fus c&uuml;zdanı
                  fotokopisi ve pasaport fotoğraf sayfası)
                </span>
              </li>
            </ul>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Aşama 3</strong>
            </h2>
            <h4 className="text-bold my-3 my-md-4">
              <strong>
                ABD green card g&ouml;r&uuml;şmesine katılmak ve onay beklemek
              </strong>
            </h4>
            <p>
              <span>Evlilik temelli </span>
              <span>ABD g&ouml;&ccedil;menlik</span>
              <span>
                {' '}
                green card s&uuml;recindeki son adım ABD green card m&uuml;lakat
                g&ouml;r&uuml;şmesidir. Yurt dışından ABD green card başvurusunda bulunan
                bir eş, kendi &uuml;lkelerindeki bir ABD b&uuml;y&uuml;kel&ccedil;iliği
                veya konsolosluğundaki bir ABD green card m&uuml;lakatına katılacaktır.
              </span>
              <span>
                <br />
              </span>
              <span>
                ABD green card arayan eş, daha sonra pasaportuna ABD'ne seyahat etmesine
                izin veren bir{' '}
              </span>
              <span>Amerika g&ouml;&ccedil;menlik başvurusu</span>
              <span>
                {' '}
                vize damgası alacaktır. USCIS G&ouml;&ccedil;men &Uuml;cret Online
                &ouml;denmelidir b&ouml;ylece ABD green card ve{' '}
              </span>
              <span>ABD g&ouml;&ccedil;menlik</span>
              <span>
                {' '}
                verilebilir. ABD green card genellikle eşin gelişinden 2-3 hafta sonra
                &ccedil;iftin ABD adresine postalanır.
              </span>
              <span>
                <br />
              </span>
              <span>Bundan sonra ne </span>
              <span>olacağı </span>
              <span>Amerika&rsquo;ya g&ouml;&ccedil;menlik </span>
              <span>, ABD </span>
              <span>green cardın onaylandığı andaki evlilik s&uuml;resine bağlıdır:</span>
            </p>
            <h4 className="text-bold my-3 my-md-4">
              <strong>
                <em>
                  İki yıldan az s&uuml;reli evli &ccedil;iftler i&ccedil;in ABD Green Card
                </em>
              </strong>
            </h4>
            <p>
              <span>
                Eş, bir "koşullu" ABD green card alacaktır. Koşullu ABD green cardlar iki
                yıl ge&ccedil;erlidir. &Ccedil;iftler, kalıcı bir ABD green card elde
                etmek i&ccedil;in, koşullu ABD green cardın sona ermesinden hemen
                &ouml;nce 90 g&uuml;nl&uuml;k s&uuml;re boyunca I-751 Formunu
                doldurmalıdır ve{' '}
              </span>
              <span>g&ouml;&ccedil;menlik b&uuml;rosuna</span>
              <span> teslim etmelidirler.</span>
            </p>
            <h4 className="text-bold my-3 my-md-4">
              <strong>
                <em>
                  İki yıldan fazla s&uuml;reli evli &ccedil;iftler i&ccedil;in ABD Green
                  Card
                </em>
              </strong>
            </h4>
            <p>
              <span>
                Eş, bir IR1 veya 10 yıl ge&ccedil;erli "kalıcı" bir ABD green card
                alacaktır. &Ccedil;oğu durumda, bu 10 yıllık ABD green cardı yenilemek
                basit bir s&uuml;re&ccedil;tir ve &ccedil;iftin evliliklerinin
                ger&ccedil;ekliğini yeniden kanıtlamasını gerektirmez.
              </span>
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Avukatımızla Bugün bir Görüşme Planlayın"
        buttonText="AVUKATIMIZA DANIŞIN"
      />

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12">
            <h2 className="text-bold my-3 my-md-4">
              <strong>EB-5 vizesi nedir?</strong>
            </h2>
            <p>
              <span>
                EB-5 vize ABD işinde yatırım yapmak, yabancıların EB-5 vize yoluyla ABD
                green card almak ve yasal kalıcı oturma izni konusunda sağlayan Amerika
                EB-5 yatırımcı vize programında yatırımla ABD green card almak i&ccedil;in
                bir yoldur.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>EB-5 Vize Yatırım Gereksinimleri</strong>
            </h2>
            <p>
              <span>
                Karşılamak i&ccedil;in EB-5 yatırımcı vize şartını, yabancı yatırımcıların
                k&acirc;r amacı g&uuml;tmeyen ABD ticari kuruluşun bir
                &ldquo;riskli&rdquo; sermaye yatırımı yapmak zorundadır. Gerekli yatırım
                tutarı, EB-5 yatırım yaptığınız projenin coğrafi konumuna bağlı olarak
                900.000 $ veya 1,8 milyon $ 'dır. EB-5 yatırımları, en az iki yıldır var
                olan 10 tam zamanlı ABD işinin yaratılmasına yol a&ccedil;malıdır.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Her Yıl Ka&ccedil; EB-5 Vizesi Veriliyor?</strong>
            </h2>
            <p>
              <span>USCIS ve </span>
              <span>g&ouml;&ccedil;menlik b&uuml;rosu,</span>
              <span>
                {' '}
                her mali yıl EB-5 yatırımcıları i&ccedil;in yaklaşık 10.000 vize
                ayırmaktadır. 2011'den beri EB-5 programına katılanların sayısındaki
                b&uuml;y&uuml;k artış nedeniyle, EB-5 vize kategorisi belirli
                &uuml;lkelerden bireyler i&ccedil;in uzun bir bekleme s&uuml;resine
                sahiptir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>EB-5 B&ouml;lgesel Merkez Yatırımlar</strong>
            </h2>
            <p>
              <span>
                EB-5 yatırımcı vizesi başvuru sahiplerinin iki ana EB-5 yatırım
                se&ccedil;eneği vardır. Yatırımcılar ya doğrudan ya da EB-5 B&ouml;lge
                Merkezi tarafından desteklenen bir yatırım yoluyla EB-5 vizesi yatırımı
                yapabilirler. Doğrudan EB-5 yatırımcılar kendi EB-5 yatırım projelerini
                bulmalıdır{' '}
              </span>
              <span>Amerika vatandaşlık başvurusu</span>
              <span> i&ccedil;in.</span>
              <span>
                <br />
              </span>
              <span>
                EB-5 yatırımcı vizesi başvuru sahipleri ayrıca EB-5 B&ouml;lge Merkezi
                sponsorluğunda bir yatırım yapabilirler. Bu se&ccedil;enek, EB-5
                yatırımlarından maksimum getiri elde etmek yerine EB-5
                g&ouml;&ccedil;menlik hedefleriyle daha fazla ilgilenenler ve EB-5
                yatırımlarının g&uuml;nl&uuml;k y&ouml;netimini denetlemek istemeyenler
                i&ccedil;in en iyisi olabilir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>EB-5 İş Gereksinimleri</strong>
            </h2>
            <p>
              <span>
                USCIS'e g&ouml;re, EB-5 yatırımı, ABD'de nitelikli olan &ccedil;alışanlar
                i&ccedil;in en az 10 tam zamanlı istihdam yaratmalıdır. İstihdamın bu
                şekilde yaratılması EB-5 yatırımcının şartlı daim&icirc; ikametgahı ve ABD
                girişinden sonraki iki yıl i&ccedil;inde yapılmalıdır. EB-5 projelerinde
                yaratılan işler doğrudan, dolaylı veya teşvikli olarak tanımlanır.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>EB-5 Yatırımcı Vize Başvuru S&uuml;recine Genel Bakış</strong>
            </h2>
            <p>
              <span>
                EB-5 vize başvuru sahipleri, ABD'de daim&icirc; ikamet izni almak
                i&ccedil;in &uuml;&ccedil; genel adımı takip etmelidir: EB-5 Yatırımcı
                başına 10 doğrudan tam zamanlı ABD işi yaratacak bireysel bir işletmeye
                yatırım yapar (EB-5 doğrudan yatırım)
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Yatırımcının EB-5 yatırım başına 10 doğrudan, dolaylı veya teşvikli iş
                yaratabileceği, USCIS tarafından belirlenmiş b&ouml;lgesel merkez
                aracılığıyla EB-5 yatırım yapar.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                I-526 onayının ardından ve EB-5 yatırımcının doğduğu &uuml;lkeye
                uygulanabilir bir vize birikimi olmaması koşuluyla, EB-5 yatırımcı koşullu
                daim&icirc; ikamet başvurusunu bir I-485, Stat&uuml; D&uuml;zeltme
                Başvurusu veya DS-260, G&ouml;&ccedil;men vizesine &ccedil;evirebilir. Bu,
                ABD green card ve{' '}
              </span>
              <span>g&ouml;&ccedil;menlik b&uuml;rosu</span>
              <span>
                , EB-5 yatırımcıya ve uygun bakmakla y&uuml;k&uuml;ml&uuml; olduğu
                kişilere verecek olan dilek&ccedil;edir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>EB-5 vize zaman &ccedil;izelgesi nedir?</strong>
            </h2>
            <p>
              <span>
                İlk adım şu anda 52,5 aya 35 aylık tahmini EB-5 işlem s&uuml;resine sahip
                bir EB-5 I-526 Dilek&ccedil;edir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                I-526 Dilek&ccedil;esinin onaylanması &uuml;zerine, bir yabancı uyruklu,
                ancak doğdukları &uuml;lkeye bağlı olarak vize gereklidir. Bu nedenle,
                EB-5 işlem s&uuml;resi, her mali yılda verilebilecek EB-5 vize sayısındaki
                &uuml;lke başına sınırlamalar nedeniyle EB-5 başvuru sahibinin doğduğu
                &uuml;lkeye g&ouml;re değişir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Bir EB-5 vize numarasının mevcut olduğunu varsayarsak, eğer bir yabancı
                &uuml;lke vatandaşı ABD dışındaysa, Ulusal Vize Merkezine bir DS-260,
                G&ouml;&ccedil;men EB-5 Vizesi Elektronik Başvurusu yapacaklar, yerel
                Konsoloslukları veya B&uuml;y&uuml;kel&ccedil;iliklerinde bir EB-5 vize
                g&ouml;r&uuml;şmesine katılacaklar ve daha sonra EB-5 vizesi ile{' '}
              </span>
              <span>ABD g&ouml;&ccedil;menlik b&uuml;rosu</span>
              <span>
                {' '}
                ile iletişime ge&ccedil;eceklerdir. ABD'ye EB-5 vizesi ile giriş yaptıktan
                sonra, yabancı uyruklulara koşullu ABD Green Card verilecektir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Bir yabancı &uuml;lke vatandaşı belirli stat&uuml;lerde ABD'nin
                i&ccedil;indeyse, stat&uuml;s&uuml;n&uuml; daim&icirc; ikametg&acirc;h
                stat&uuml;s&uuml;ne ayarlamak i&ccedil;in EB-5 Form I-485, Daim&icirc;
                İkamet Kayıt Başvurusu veya Stat&uuml; Ayarlama başvurusunda
                bulunabilirler.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Hızlandırılmış I-526 işlemi nedir (EB-5 vizesi)?</strong>
            </h2>
            <p>
              <span>
                USCIS, aşağıdakilere dayalı olarak herhangi bir EB-5 g&ouml;&ccedil;menlik
                başvurusu veya dilek&ccedil;eye hızlandırılmış EB-5 işlem verme yetkisine
                sahiptir:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>"Bir şirket veya kişiye ciddi mali zarar;"</span>
              </li>
              <li>
                <span>"Acil insani nedenler;"</span>
              </li>
              <li>
                <span>
                  "ABD H&uuml;k&uuml;metinin &ccedil;ıkarlarını zorlamak (Savunma
                  Bakanlığı veya DHS i&ccedil;in acil durumlar veya diğer kamu
                  g&uuml;venliği veya ulusal g&uuml;venlik &ccedil;ıkarları gibi); veya"
                </span>
              </li>
            </ul>
            <h2 className="text-bold my-3 my-md-4">
              <strong>B-1 / B-2 Ziyaret&ccedil;i Vizesi</strong>
            </h2>
            <h2 className="text-bold my-3 my-md-4">
              <strong>B1 / B2 vizesi nedir</strong>
              <strong>?</strong>
            </h2>
            <p>
              <span>B-1 / B-2 vizesi</span>
              <span>
                , sahibinin iş veya turizm amacıyla ABD'ne seyahat etmesine izin veren
                ge&ccedil;ici, g&ouml;&ccedil;men olmayan bir vizedir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                F-1 vizeleri kapsamındaki &ouml;ğrenci seyahatleri hari&ccedil;,
                ge&ccedil;ici seyahat nedenlerinin neredeyse tamamı B vizeleri
                kapsamındadır. B-1 vizesi iş seyahatlerini, B-2 vizesi ise tatil veya aile
                ziyaretleri gibi turizmi kapsar.{' '}
              </span>
              <span>B1 B2 vizesi nedir</span>
              <span>?</span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>B1 / B2 vizesi</span>
              <span>
                {' '}
                altındaki seyahat nedenleri, bunlarla sınırlı olmamak &uuml;zere
                aşağıdakileri i&ccedil;erir:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  Bir s&ouml;zleşmeyi m&uuml;zakere etmek veya iş toplantılarına katılmak
                  gibi iş yapmak
                </span>
              </li>
              <li>
                <span>
                  Bir meslek, eğitim veya mevcut iş &ccedil;abasıyla ilgili bir konferansa
                  katılmak
                </span>
              </li>
              <li>
                <span>Turistik faaliyetlerde bulunmak veya tatile &ccedil;ıkmak</span>
              </li>
              <li>
                <span>Aile ziyareti</span>
              </li>
              <li>
                <span>Tıbbi tedavi g&ouml;rmek</span>
              </li>
            </ul>
            <p>
              <span>B-1 / B-2 vizesi</span>
              <span>
                {' '}
                kalıcı oturma izni vermez- bu ge&ccedil;ici bir vizedir- ancak vize sahibi{' '}
              </span>
              <span>g&ouml;&ccedil;menlik b&uuml;rosu</span>
              <span> ABD green card ve </span>
              <span>ABD g&ouml;&ccedil;menlik</span>
              <span> başvurusunda bulunabilir. </span>
              <span>B1 / B2 vizesi</span>
              <span> i&ccedil;in verilen maksimum s&uuml;re 180 g&uuml;nd&uuml;r.</span>
              <span>
                <br />
              </span>
              <span>Bu vize, ABD'ne birden fazla giriş yapılmasına izin verir.</span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>B-1 / B-2 Vizesi nedir</strong>
              <strong>? ve Uygunluğu</strong>
            </h2>
            <p>
              <span>B-1 B-2 vizesi</span>
              <span>
                ne hak kazanabilmek i&ccedil;in, başvuru sahibi seyahatin ge&ccedil;ici
                olduğunu ve seyahatin t&uuml;m masraflarını kendilerinin
                karşılayabileceğini kanıtlamalıdır. Ek olarak, ABD'nde kaldıkları
                s&uuml;re boyunca kalma ve ABD dışındaki bir konutu terk etme niyetinde
                olmadıklarını kanıtlamaları gerekir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>Başvuru i&ccedil;in gerekli belgeler:</span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  D&ouml;n&uuml;ş tarihinden itibaren altı ay ge&ccedil;erliliği olan bir
                  pasaport
                </span>
              </li>
              <li>
                <span>Bu gereksinimleri karşılayan yeni bir dijital fotoğraf</span>
              </li>
              <li>
                <span>Varsa, ABD'ne &ouml;nceki beş seyahatin belgeleri</span>
              </li>
              <li>
                <span>
                  Seyahat, konaklama ve yaşam masrafları dahil seyahatin t&uuml;m
                  masraflarını karşılayacak fon kanıtı
                </span>
              </li>
              <li>
                <span>
                  İş, m&uuml;lk veya aile gibi kendi &uuml;lkeleriyle bağlayıcı bağların
                  kanıtı
                </span>
              </li>
            </ul>
            <h2 className="text-bold my-3 my-md-4">
              <strong>B-1 / B-2 Vize &Uuml;creti</strong>
            </h2>
            <p>
              <span>
                B vizesi i&ccedil;in devlet başvuru &uuml;creti 160 $ 'dır. Buna belge ve
                delil toplama ve pasaport fotoğrafı alma maliyetinin dahil olmadığını
                unutmayın.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>B1 / B2 Vizesi- Vize Başvuru S&uuml;reci</strong>
            </h2>
            <p>
              <span>
                Başvurular, &uuml;lkenizdeki ABD B&uuml;y&uuml;kel&ccedil;iliği veya
                Konsolosluğu tarafından işleme alınabilir. &Uuml;&ccedil;&uuml;nc&uuml;
                bir &uuml;lkedeki bir el&ccedil;ilik veya konsoloslukta m&uuml;lakat
                yapabilirsiniz, ancak vize başvurusunun işleme alınması daha uzun
                s&uuml;recektir. Daha fazla bilgi i&ccedil;in{' '}
              </span>
              <span>ABD g&ouml;&ccedil;menlik</span>
              <span> b&uuml;rosu ile iletişime ge&ccedil;ebilirsiniz.</span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                Bir başvuru tamamen kabul edilmeden &ouml;nce bir g&ouml;r&uuml;şme
                yapılması gerekecektir.
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  DS-160 formundaki &Ccedil;evrimi&ccedil;i G&ouml;&ccedil;men Olmayan
                  Başvuru formu doldurulmalıdır. Formu &ccedil;evrimi&ccedil;i olarak
                  dosyalayın ve g&ouml;r&uuml;şmeniz i&ccedil;in ihtiyacınız olacağından
                  onay sayfasını yazdırın.
                </span>
              </li>
              <li>
                <span>Dijital fotoğrafınızı y&uuml;kleyin</span>
              </li>
            </ul>
            <p>
              <span>
                S&uuml;recin bir par&ccedil;ası olarak M&uuml;rekkepsiz, dijital parmak
                izi taramaları yapılır.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>E2 Yatırımcı vize</strong>
            </h2>
            <p>
              <span>
                E2 Yatırımcı vize Anlaşması Yatırımcı vizesi, E2 yatırımcının işletmeyi
                y&ouml;nlendirmek ve geliştirmek i&ccedil;in ABD'de yaşamasına ve
                &ccedil;alışmasına izin verir ve İcra, Y&ouml;netim ve Temel beceriler
                &ccedil;alışanlarının girişine izin verir.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>ABD'de iş devam ettiği s&uuml;rece E2 Yatırımcı vizesi </span>
              <span>Amerika&rsquo;ya g&ouml;&ccedil;menlik</span>
              <span> s&uuml;resiz olarak uzatılmaya devam edebilir ve </span>
              <span>Amerika vatandaşlık başvurusu</span>
              <span> yapılabilir.</span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>Bir&ccedil;ok işletme de&nbsp;</span>
              <strong>L1 vize</strong>
              <span>
                &nbsp;se&ccedil;eneğini d&uuml;ş&uuml;nmek isteyebilir. Y&ouml;neticiler
                ve y&ouml;neticiler i&ccedil;in L1 vize kategorisinin bir avantajı,
                &ouml;nce bir L1 vizesi almanın gelecekte bir&nbsp;
              </span>
              <strong>"ABD green card"</strong>
              <span>&nbsp;almayı kolaylaştırabilmesidir.</span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>E2 Yatırımcı vize yatırım gereksinimleri</strong>
            </h2>
            <p>
              <span>
                Bir işletmenin E2 Yatırımcı vize kaydı i&ccedil;in başvurduğunuzda, para
                yatırma s&uuml;recinde olmanız gerekir. Bir d&uuml;zenleme
                m&uuml;mk&uuml;n olmadık&ccedil;a, E2 başvurmadan &ouml;nce &ouml;nemli
                bir E2 yatırım yaptığınızı g&ouml;stermelisiniz.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>E2 Yatırımcı vize ve ABD green card se&ccedil;eneği</strong>
            </h2>
            <p>
              <span>
                EB-5 g&ouml;&ccedil;men yatırımcı ABD green card Programı kapsamında en az
                900.000 $ yatırım yapmak istemeyen yatırımcılar i&ccedil;in E2 Yatırımcı
                vize anlaşmalı yatırımcı vizesi kategorisi (veya muhtemelen L1 vizesi)
                dikkate alınmaya değer.
              </span>
              <span>
                <br />
              </span>
              <span>
                <br />
              </span>
              <span>
                E2 yatırımcı vize s&uuml;resiz olarak uzatılmaya devam edilebilirken, bu
                "ABD green card" sahibi olmakla aynı şey değildir. ABD green card
                istiyorsanız, &ouml;nce L1 vizesi altında uluslararası bir y&ouml;netici
                veya y&ouml;netici olarak girmeye &ccedil;alışmak bir fikir olabilir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>E2 yatırımcı vize Anlaşması Yatırımcı Başvuru Koşulları</strong>
            </h2>
            <p>
              <span>
                Nitelikli &uuml;lkelerden gelen E2 yatırımcılar, işlerini
                'Y&ouml;nlendirmek ve Geliştirmek' i&ccedil;in E2 Yatırımcı vizesi
                anlaşmalı E2 yatırımcı vizesine başvurabilirler. Ayrıca, &ouml;zellikle
                orta ve b&uuml;y&uuml;k &ouml;l&ccedil;ekli işletmeler i&ccedil;in faydalı
                olabilecek kilit y&ouml;netici ve uzman &ccedil;alışanlar i&ccedil;in E2
                Yatırımcı vizesi vizelerine başvurabilirler.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>
                E2 Yatırımcı vizesi anlaşması Yatırımcı başvuru sahipleri aşağıdaki
                koşulları karşılamalıdır:
              </strong>
            </h2>
            <p>
              <span>
                ABD'de &ouml;nemli bir E2 yatırım olmuştur veya bazı durumlarda para, E2
                Yatırımcı vizesi verilirse transfer edilmek &uuml;zere emanet olarak
                tutulmaktadır. Spesifik bir E2 yatırım gerekliliği yoktur. &Ouml;rneğin BT
                sekt&ouml;r&uuml;ndeki bazı işletmeler, yaklaşık 10.000 $ 'lık E2
                yatırımlara hak kazanabilir.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>
                E2 Yatırımcı vizesi Vize Konsolosluğu İşlemleri veya Stat&uuml;
                Değişikliği
              </strong>
            </h2>
            <p>
              <span>
                &Ccedil;oğu durumda Konsolosluk E2 işleminin kullanılması ve yurtdışındaki
                bir ABD B&uuml;y&uuml;kel&ccedil;iliğine başvuru yapılması tavsiye edilir.
                Konsolosluk İşleminin avantajları aşağıdaki gibidir:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  E2 Yatırımcı vizesi Kaydı ve E2 Yatırımcı vizesinin
                  B&uuml;y&uuml;kel&ccedil;ilikte daha uzun s&uuml;re verilmesi olağandır.
                  Bu, uyruğa ve ABD B&uuml;y&uuml;kel&ccedil;iliğindeki politikaya
                  bağlıdır. Bununla birlikte, &ccedil;oğu durumda ABD dışında E2
                  başvururken ge&ccedil;erlilik s&uuml;resi beş yıldır.
                </span>
              </li>
            </ul>
            <p>
              <span>
                ABD'de stat&uuml; değişikliği yoluyla E2 Yatırımcı vizesine başvurmanın
                avantajı, prim işlemiyle on beş g&uuml;n i&ccedil;inde bir karara
                varılabilmesidir. &Ouml;ncelikle, vize feragat programı kapsamında olmayan
                ABD i&ccedil;in g&ouml;&ccedil;men olmayan{' '}
              </span>
              <span>Amerika vatandaşlık başvurusu</span>
              <span> bir vizeye sahip olmanız gerekecektir.</span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>E2 Yatırımcı vizesi Vize &Ccedil;alışanları</strong>
            </h2>
            <p>
              <span>
                Asıl başvuru sahibi bir E2 Yatırımcı vizesi Anlaşması E2 Yatırımcısı
                olarak kayıt aldıktan sonra, genellikle yatırımcı ile aynı uyrukta olan
                nitelikli &ccedil;alışanlar i&ccedil;in E2 Yatırımcı vizesi alabilirler.
                E2 Yatırımcı vizesi kaydının alınması E2 yargı b&ouml;lgesine bağlı olarak
                birka&ccedil; ay s&uuml;rebilir ve E2 Yatırımcı vizesi &ccedil;alışan E2
                vizesi almak genellikle yaklaşık iki hafta s&uuml;rer.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>E2 vizelerine iki t&uuml;r &ccedil;alışan hak kazanır:</strong>
              <strong>
                <br />
              </strong>
              <strong>
                <em>E2 Yatırımcı vizesi Y&ouml;neticiler ve Denet&ccedil;iler</em>
              </strong>
            </h2>
            <p>
              <span>
                Y&ouml;neticiler ve Y&ouml;neticiler &uuml;st d&uuml;zey bir pozisyonda
                olmalı ve t&uuml;m işi veya işletmenin &ouml;nemli bir
                b&ouml;l&uuml;m&uuml;n&uuml; y&ouml;netmelidir. Daha b&uuml;y&uuml;k
                işletmelerde, E2 işletmenin sahipleri ger&ccedil;ekte pratikte ABD'de
                fazla zaman ge&ccedil;irmeyebilirler.
              </span>
            </p>
            <h2 className="text-bold my-3 my-md-4">
              <strong>
                <em>
                  E2 Yatırımcı vizesi Uzmanı veya Temel Nitelikli &Ccedil;alışanlar:
                </em>
              </strong>
            </h2>
            <p>
              <span>
                Bu, ABD'de olduk&ccedil;a yetenekli seviyeli insanları istihdam etmenin
                iyi bir yoludur. Bunu g&ouml;stermelisiniz:
              </span>
            </p>
            <ul className="list-bullets">
              <li>
                <span>
                  &Ccedil;alışan, ABD'de bulunması zor olabilecek işletme hakkında E2
                  uzmanlık bilgisine sahiptir.
                </span>
              </li>
              <li>
                <span>
                  &Ccedil;alışan, ana t&uuml;ccarın veya yatırımcının ABD'deki işinin
                  y&uuml;r&uuml;t&uuml;lmesi i&ccedil;in gereklidir.
                </span>
              </li>
            </ul>
            <h5 className="text-bold my-3 my-md-4">
              <strong>
                Uyruğa bağlı olarak, E vizeleri i&ccedil;in olağan vize başvuru
                &uuml;cretine ek olarak genellikle 105 $ 'lık bir' vize d&uuml;zenleme
                &uuml;creti gerektiğini unutmayın.
              </strong>
            </h5>
            <h2 className="text-bold my-3 my-md-4">
              <strong>Sık&ccedil;a Sorulan Sorular:</strong>
            </h2>
            <h6 className="text-bold my-3 my-md-4">
              <strong>
                E2 Yatırımcı vizesi Anlaşması Yatırımcı Vize Programı i&ccedil;in minimum
                yatırım nedir?
              </strong>
            </h6>
            <p>
              <span>
                E2 Yatırımcı vizesi E2 Yatırımcı programı kapsamında bir işletmeyi
                kaydetmek i&ccedil;in ne kadar yeterli bir yatırım olduğunu kimse
                bilmiyor. ABD B&uuml;y&uuml;kel&ccedil;iliği veya Konsolosluğu'ndaki E2
                Yatırımcı vizesi Antlaşma Birimi'nin veya ABD'deki dosyalarda{' '}
              </span>
              <span>ABD g&ouml;&ccedil;menlik</span>
              <span> b&uuml;rosu ve USCIS'in takdirine kalmıştır.</span>
            </p>
            <h6 className="text-bold my-3 my-md-4">
              <strong>E2 Yatırımcı vizesi i&ccedil;in işlem s&uuml;releri nedir?</strong>
            </h6>
            <p>
              <span>
                Bu, E2 başvurduğunuz yere ve uygulama t&uuml;r&uuml;ne bağlı olarak
                b&uuml;y&uuml;k &ouml;l&ccedil;&uuml;de değişebilir. İşletme halihazırda
                bir E2 Yatırımcı vizesi Anlaşması E2 Yatırımcı işi olarak kayıtlıysa, bu
                daha hızlı ve{' '}
              </span>
              <span>Amerika vatandaşlık başvurusu</span>
              <span> Kolay hale getirmelidir.</span>
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Avukatımızla Bugün bir Görüşme Planlayın"
        buttonText="AVUKATIMIZA DANIŞIN"
      />
    </Layout>
  );
}

AbdGocmenLikAvukatlari.propTypes = {};

export default AbdGocmenLikAvukatlari;
